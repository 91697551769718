import { MAPBOX_API_KEY } from "../config";


function getProduct(priceId) {
    return PRODUCTS_LIVE.find(p => p.id === Number(priceId))
}

function computeTax(price) {
    return (DEFAULT_TAX_IVA * price).toFixed(2)
}

export const totalInfo = (priceId) => {
    const product = getProduct(priceId)
    const tax = computeTax(product.price)
    return {
        name: product.name,
        subtotal: (product.price - tax).toFixed(2),
        tax,
        total: product.price
    }
}

export const fetchOptions = (body, method) => {
    return {
        method,
        headers: {
            "Content-Type": "application/json"
        },
        credentials: 'include',
        ...(body ? { body: JSON.stringify(body) } : {})
    }
}

export const fetchFireRisk = async (lat, lon)=> {
    try {
        
        const url =`https://api.mapbox.com/v4/mapbox2gnd.mapa-risc-incendis/tilequery/${lon},${lat}.json?access_token=${MAPBOX_API_KEY}`
        const resp = await fetch(url)

        if(resp && resp.ok){
            const data = await resp.json()

            return data.features[0].properties.perill
        }

        return 
    }catch(e){
        console.log(e)
    }
}


export const computePointWithinPolygon = (theGeom) => {
    const initialPoint = theGeom.coordinates[0][0][0]
    const finalPoint = theGeom.coordinates[0][0][Math.floor(theGeom.coordinates[0][0].length / 2)]

    return {
        long: (initialPoint[0] + finalPoint[0]) / 2,
        lat: (initialPoint[1] + finalPoint[1]) / 2
    }
}

export const filterAndReturnCultiuFeatures = (features, e, zoom) => {
    if (features && features.length > 0) {
        const haFeature = features.filter((feat) => feat.properties.ha);
        const campanyaFeatures = features.filter(
            (feat) => feat.properties.campanya
        );
        const cultiusFeatures = features.flatMap((feat) =>
            feat.properties.hasOwnProperty("campanya")
                ? +feat.properties.campanya
                : []
        );

        const cultiuFeature =
            campanyaFeatures[
            cultiusFeatures.indexOf(Math.max(...cultiusFeatures))
            ];
        
        const fullFeature = {
            id: haFeature[0].properties.id_par,
            idRec: haFeature[0].properties.id_rec,
            ha: +haFeature[0].properties.ha,
            cultiu: cultiuFeature?.properties.cultiu,
            any: +cultiuFeature?.properties.campanya,
            source: haFeature[0].source,
            cultiuFull: campanyaFeatures.map(cf=>cf.properties).slice(0, 6),
            lat: e.lngLat.lat,
            lon: e.lngLat.lng,
            zoom

        };


        return { cultiuFeature, fullFeature }

    }

    return undefined
}

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}


//Ported from wehatecaptchas

// Variables
const time = Math.floor(Date.now() / 1000);


export const getQuestion = async (worker, difficulty = WHC_DIFFICULTY) => {

    try {
        const response = await sharedService.fetchCaptchaQuestion({
            "endpoint": "question"
        })

        if (!response.ok) {
            return undefined
        }
        const data = await response.json();
        if (data) {
            worker.postMessage({
                "question": data.data.question,
                "time": time,
                "difficulty": difficulty
            });
            return true
        }

        return undefined
    } catch (e) {
        return undefined
    }

}







export const initWhc = () => {
    const worker = createWorker(myWorker)
    getQuestion(worker);
    return worker
}



export const workerMessageEventListener = (worker, callback) => {
    worker.addEventListener("message", callback, false);

}

export const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
}

