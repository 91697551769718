import { createStore } from "vuex";
import { supabase } from "../supabase/init";
import { fetchFireRisk } from "../utils/utils";
import { DEFAULT_FIRE_TIMESTAMP, ENV } from "../config";

export default createStore({
  state: {
    fires: new Map(),
    newFire: undefined,
    switchFilters: undefined,
    firesSub: undefined
  },
  actions: {
    onMapInstance: ({commit}, map)=>{
      commit('setMapInstance',map)
    },
    onSwitchFilters: ({commit})=>{
      commit('switchFilters')
    },
    //default timespan is 48h
    onFetchFires: async ({commit}, payload)=>{
      try{        
        let fires, error;

        const fireId = payload?.fireId
        const timespan = payload?.timespan || DEFAULT_FIRE_TIMESTAMP
        if(fireId){
          //fetch single fire by its id
          let resp = await supabase
          .from(ENV === "production"
          ? "fires"
          : "fires_duplicate")
          .select("*")
          .eq("id", fireId)
          .order("created_at", { ascending: true })

          fires = resp.data
          error = resp.error
          if(!error && fires && fires.length && fires[0].latitude && fires[0].longitude){
            //fetch fireRisk
            const fireRisk = await fetchFireRisk(fires[0].latitude, fires[0].longitude)

            
            if(fireRisk){
              fires = [{...fires[0], risk: fireRisk}]
            }
          }
        }else{
          let resp = await supabase
          .from(ENV === "production"
          ? "fires"
          : "fires_duplicate")
          .select(`id, where_geolocation, when_last_time, created_at, updated_at, longitude, latitude, when_timestamp, status, radius, ops, is_stabilized_fire, is_controlled_fire, is_extinguished_fire${payload?.timespan ? ', where_geolocation_full, ops_array':''}`)
          .gte("when_last_time", Date.now() - timespan)
          .order("created_at", { ascending: true })
          fires = resp.data
          error = resp.error
        }
       
        if(error){
          console.log(error)
        }

      fires.forEach((fire) => {
        commit('setFire', fire)
      })



      }catch(e){
        console.log(e)
        
      }
    },
    onMapFeature: ({ commit }, mapFeature) =>
      commit("setMapFeature", mapFeature),
    onMapFeatureSelected: ({ commit }) => {
      commit("addMapFeature");
      commit("resetMapFeature");
    },
    onEnableFiresSub:({commit})=>{
      let firesSubscription;
      try{
        //enable realtime only for fire-stats page
      firesSubscription = supabase
      .channel("table-db-changes")
      .on("postgres_changes", {table: ENV === "production"
      ? "fires"
      : "fires_duplicate", schema:'public', event: "*"}, async (payload) => {
          let item = payload.new
          
          if (Object.keys(item).length > 0 && ["INSERT", "UPDATE"].includes(payload.eventType)) {
            if(payload.eventType === "INSERT"){
              commit('setNewFire', item)
            }

            commit('setFire', {...item, realtime: true})
          }
        
      })
      .subscribe()

      }catch(e){
        console.log(e)
        firesSubscription.unsubscribe()
        
      }

      commit('setFiresSub', firesSubscription)
    },
    onDisableFiresSub:({commit})=>commit('removeFiresSub'),
    onMapFeatureDeleted: ({ commit }, mf) => commit("deleteMapFeature", mf),
    onMapFeatureReset: ({ commit }) => commit("resetMapFeature"),
    onResetSeason: ({ commit }) => commit("resetSeasonId"),
    onSetCurrentMapPoint: ({commit}, point) => commit("setCurrentMapPoint", point),
  },
  mutations: {
    setFiresSub: (state, sub) => state.firesSub = sub,
    removeFiresSub: (state)=> {
      if(state.firesSub){
        state.firesSub.unsubscribe()
        state.firesSub = undefined
      }
    },
    setUserSuccess: (state, user) => {
      state.user = user;
    },
    setUserFailure: (state) => (state.user = undefined),
    loginSuccess: (state) => (state.status.loggedIn = true),
    loginFailure: (state) => (state.status.loggedIn = false),
    validEmail: (state, customer) => (state.customer = customer),
    invalidEmail: (state) => (state.customer = undefined),
    addProject: (state, project) => state.user?.projects.push(project),
    setCurrentProjectId: (state, projectId) =>
      (state.currentProjectId = projectId),
    setCurrentRegistreId: (state, registreId) =>
      (state.currentRegistreId = registreId),
    setNewRegistre: (state) => (state.newRegistre = !state.newRegistre),
    setNewProject: (state) => (state.newProject = !state.newProject),
    resetCurrentRegistreId: (state) => (state.currentRegistreId = undefined),
    setEditRegistre: (state) => (state.editRegistre = !state.editRegistre),
    addMapFeature: (state) =>
      state.currentMapFeatures.set(state.mapFeature.idRec, state.mapFeature),
    deleteMapFeature: (state, mapFeature) =>
      state.currentMapFeatures.delete(mapFeature.idRec),
    resetCurrentMapFeatures: (state) => (state.currentMapFeatures = new Map()),
    setMapFeature: (state, mapFeature) => (state.mapFeature = mapFeature),
    resetMapFeature: (state) => (state.mapFeature = undefined),
    setSubscription: (state, sub) => (state.subscription = sub),
    setInvoiceData: (state, { invoice, paymentMethodId, priceId }) =>
      (state.subscription = {
        subscription: state.subscription.subscription,
        invoice,
        paymentMethodId,
        priceId,
      }),
    setEvent: (state, event) => (state.globalEvent = event),
    setNotification: async (state, notification) => {

      state.notifications.push({
        id: state.notifications.length + 1,
        date: Date.now(),
        type: notification.type,
        message: notification.message,
        ...(notification?.url ? { url: notification.url } : {}),
      })
    },
    setNotifications: (state, notifications) => {
      const mapNot = notifications.map(n=>{
        return {
          ...n,
          db: true
        }
      })
      state.notifications.push(...mapNot)
    },
    deleteNotification: async (state, not) => {

      if(not.db){
        try{
            const resp = await UserService.deleteNotification(not)
    
            if(!resp.ok){
              throw new Error('Failed to delete notification')
            }
          
        }catch(e){
          console.error(e)
        }
      }
      state.notifications = state.notifications.filter((n) => n.id !== not.id)
    },
    setClientSecret: (state, clientSecret) =>
      (state.clientSecret = clientSecret),
    setLocationItems: (state, data) => (state.locationItems = data),
    resetLocationItems: (state) => (state.locationItems = undefined),
    setCropItems: (state, data) => (state.cropItems = data),
    resetCropItems: (state) => (state.cropItems = undefined),
    setRecinteItem: (state, data) => (state.recinteItem = data),
    resetRecinteItem: (state) => (state.recinteItem = undefined),
    setCultiveOnMapFeatures: (state, data) => {
      const lastMapFeature = Array.from(state.currentMapFeatures)[
        state.currentMapFeatures.size - 1
      ];
      const { cultiu, any, ...rest } = lastMapFeature[1];
      const newValue = {
        ...rest,
        cultiu: data.cultiu,
        any: data.any,
      };
      state.currentMapFeatures.set(lastMapFeature[0], newValue);
    },
    setSeasonId: (state, season) => (state.currentSeasonId = season),
    resetSeasonId: (state) => (state.currentSeasonId = undefined),
    setEnrolledUserToken: (state, token) => (state.enrolledUserToken = token),
    setBanc: (state, banc) => (state.banc = banc),
    setCurrentMapPoint: (state, point) => (state.currentMapPoint = point),
    setFire: (state, fire)=>(state.fires.set(fire.id, {...state.fires.get(fire.id), ...fire})),
    setNewFire: (state, fire)=>(state.newFire = fire),
    setMapInstance: (state, map)=>(state.map = map),
    switchFilters: (state)=>state.switchFilters = !state.switchFilters

  },
  getters: {
    loggedIn: (state) => state.status.loggedIn,
    recinteItem: (state) => state.recinteItem,
    registres: (state, getters) => {
      const registres = getters.project?.registres;
      if (!getters.currentSeasonId) {
        return registres;
      }
      const season = seasonsDAN.find(
        (s) => s.id === getters.currentSeasonId
      )?.name;

      return registres.filter((registre) => {
        if (!registre.season) {
          const seasonStart = season.split("-")[0];
          return registre.date.startsWith(seasonStart);
        }
        return registre.season === season;
      });
    },
    enrolledUsers: (state) => state.user?.enrolled_users,
    project: (state, getters) =>
      state.user?.projects.find(
        (project) => project.id === getters.currentProjectId
      ),
    currentMunicipi: (state, getters) => getters.project.municipi,
    registre: (state, getters) => {
      const registre = getters.registres?.find(
        (registre) => registre.id === getters.currentRegistreId
      );
      getters.currentMapFeatures.set(
        registre.form.mapFeatures.idRec,
        registre.form.mapFeatures
      );
      return registre;
    },
    currentProjectId: (state) => state.currentProjectId,
    currentRegistreId: (state) => state.currentRegistreId,
    editMode: (state) => state.editRegistre,
    currentMapFeatures: (state) => state.currentMapFeatures,
    mapFeature: (state) => state.mapFeature,
    currentSeasonId: (state) => state.currentSeasonId,
    fires: (state)=>{
      return Array.from(state.fires.values()).map(f=>{

        let fireStatus = f.status
        //compute status for backward compat.
        if(!fireStatus){
          const fireDate = new Date(f.when_timestamp ? f.when_timestamp : f.created_at);

          fireStatus = "Actiu"
        if (f.is_stabilized_fire && f.is_stabilized_fire?.toLowerCase() === "yes") {
          fireStatus = "Estabilitzat";
        }
        if (f.is_controlled_fire && f.is_controlled_fire?.toLowerCase() === "yes") {
          fireStatus = "Controlat";
        }

        if (
          f.is_extinguished_fire && f.is_extinguished_fire.toLowerCase() === "yes" ||
          Date.now() - fireDate.getTime() > 3600 * 1000 * 24 * 2
        ) {
          fireStatus = "Extingit";
        }

        
      }

    
        return {
          ...f,
          status: fireStatus,
          radius: f.radius * f.ops,
          latitude: Number(f.latitude),
          longitude: Number(f.longitude)

        }
      })
    },
    firesActive:(state, getters)=> getters.firesByStatus("Actiu"),
    firesControl:(state, getters)=> getters.firesByStatus("Controlat"),
    firesStable:(state, getters)=> getters.firesByStatus("Estabilitzat"),
    firesExtinct:(state, getters)=> getters.firesByStatus("Extingit"),
    fireById:(state, getters)=>(fireId)=> getters.fires?.find((fire) => fire.id == fireId),
    firesByStatus: (state, getters) =>(status)=>{
      const firesArray = Array.from(getters.fires.values())
      return {  
        type: "FeatureCollection",
        features: firesArray.flatMap((fire) => {
          if (!(fire.longitude && fire.latitude)) {
            return [];
          }
          
    
          const fireDate = new Date(+fire.when_last_time).getTime()


          // filter fires older than timestamp
          if((Date.now() - fireDate) > DEFAULT_FIRE_TIMESTAMP){
            return []
          }

          if(fire.status !== status){
            return []
          }

          return [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [fire.longitude, fire.latitude],
              },
              properties: {
                ...fire,
              },
            },
          ];
        }),
      };
    },
    firePointsHistoric: (state) =>{
      const firesArray = Array.from(state.fires.values())
      return {
        type: "FeatureCollection",
        features: firesArray.flatMap((fire) => {
          if (!(fire.longitude && fire.latitude)) {
            return [];
          }
          let fireDate;
          if (!(fire.when_timestamp)){
            fireDate = fire.created_at
          }else{
            fireDate = new Date(fire.when_timestamp).getTime()

          }
          // filter fires more recent than 24h
          if((Date.now() - fireDate) < 1000 * 3600 * 24 * 1){
            return []
          }

          // filter fires older than 48h
          if((Date.now() - fireDate) > 1000 * 3600 * 24 * 2){
            return []
          }

          return [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [fire.longitude, fire.latitude],
              },
              properties: {
                ...fire,
              },
            },
          ];
        }),
      };
    }
  },
});
