export const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:6300'

export const endpoints = {
    auth: '/auth',
    user: '/user',
    service: '/service',
    projects: '/user/projects',
    project: '/project',
    recinte: '/recinte',
    captcha: 'https://wehatecaptchas.com/api.php',
}


export const MAPBOX_API_KEY = import.meta.env.VITE_MAPBOX_API_KEY
export const APP_VERSION = '1.2.4'
export const ENV = import.meta.env.VITE_APP_ENV
export const IS_MOB_APP= import.meta.env.VITE_APP_MOBILE_APP
export const DEFAULT_FIRE_TIMESTAMP = 1000 * 3600 * 12 * 1 //12h