import { createRouter, createWebHistory } from 'vue-router';
import store from './store/store'; // Adjust the path as necessary

const routes = [
  {
    path: '/',
    ...(import.meta.env.VITE_APP_MOBILE_APP
      ? { redirect: { name: 'map' } }
      : {
          name: 'home',
          component: () => import('./layouts/Home.vue'),
        }),
  },
  {
    path: '/mapa',
    name: 'map',
    component: () => import('./components/Map.vue'),
  },
  {
    path: '/fire/:id',
    name: 'fire-stats',
    component: () => import('./components/FireStats.vue'),
  },
  {
    path: '/list',
    name: 'fire-list',
    component: () => import('./components/FireList.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

//since we cache the map component, we need to fetch fire data before navigation
router.beforeEach(async(to, from, next) => {
  if (to.name === 'map') {
    await store.dispatch('onFetchFires')
      .then(() => {
        next();
      })
      .catch(err => {
        console.error('Error dispatching onFetchFires action:', err);
        next();
      });
  } else {
    next();
  }
});

export default router;
