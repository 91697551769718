import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import router from "./router";
import store from "./store/store";

import ToastPlugin from "vue-toast-notification";

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "vue-toast-notification/dist/theme-bootstrap.css";
import * as Sentry from "@sentry/capacitor";
import { Smartlook } from '@awesome-cordova-plugins/smartlook';

import { PushNotifications } from "@capacitor/push-notifications";
import { supabase } from "./supabase/init";
import { ENV } from "./config";


if (import.meta.env.VITE_APP_MOBILE_APP) {
  Sentry.init({
    dsn: "https://3449469310d04f71908f30a2d121af47@o4505330285674496.ingest.sentry.io/4505330287575040",
    release: "cat.focs",
    dist: "1",
  });

  //smartlook
  Smartlook.setProjectKey({ key: '2538c04f018c6028516af0679873a0baccbc97c2' });
  Smartlook.start();
  
}



const addListeners = async () => {
  await PushNotifications.addListener('registration', async token => {
    const regToken = token.value
    const {data, error} = await supabase.from(ENV === "production"
    ? "push_notifications"
    : "push_notifications_duplicate").upsert([{token: regToken}])

    if (error) {
      console.error(error)
    }
  });

  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    const focId = notification?.notification?.data.id

    if(focId){
      router.push({path:`/fire/${focId}`})

    }
  }
  );
}

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive && permStatus.receive !== "granted") {
    console.error("User denied permissions!");
  }

  await PushNotifications.register();
};

(async () => {
  if (import.meta.env.VITE_APP_MOBILE_APP) {
    try {
      await registerNotifications();
      await addListeners()
    } catch (e) {
      console.error(e);
    }
  }
})();

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ToastPlugin);

app.mount("#app");
